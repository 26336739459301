<template>
    <div class="page">
        <page-header></page-header>
        <div class="page-banner">
            <send-form></send-form>
            <home-banner></home-banner>
        </div>
        <recommend-list v-if="recommendShow"></recommend-list>
        <img class="page-img" :src="home_img" alt="">
        <div class="page-peiple">
            <div class="page-peiple-left">
                <girl-list :pageShow="pageShow"></girl-list>
            </div>
            <div class="page-peiple-right">
                <love-gift></love-gift>
                <love-wall></love-wall>
            </div>
        </div>
        <page-bottom></page-bottom>
        <page-fixed></page-fixed>
        <transition name="el-zoom-in-top">
            <user-form-list v-if="$store.state.sayShow"></user-form-list>
        </transition>
        <transition name="el-zoom-in-top">
            <gift-list v-if="$store.state.giftShow"></gift-list>
        </transition>
        <transition name="el-zoom-in-top">
            <pay v-if="$store.state.payShow"></pay>
        </transition>
        <transition name="el-zoom-in-top">
            <upgrade v-if="$store.state.upgradeShow"></upgrade>
        </transition>
    </div>
</template>

<script>
import PageHeader from '../../components/pageHeader.vue'
import HomeBanner from '../../components/home/homeBanner.vue'
import sendForm from '../../components/sendForm.vue'
import RecommendList from '../../components/girlData/recommendList.vue'
import GirlList from '../../components/girlData/girlList.vue'
import LoveGift from '../../components/home/loveGift.vue'
import LoveWall from '../../components/home/loveWall.vue'
import PageBottom from '../../components/pageBottom.vue'
import PageFixed from '../../components/pageFixed.vue'
import userFormList from '../../components/say.vue'
import GiftList from '../../components/gift.vue'
import Pay from '../../components/pay.vue'
import Upgrade from '../../components/upgrade.vue'
import { regionData, CodeToText } from "element-china-area-data"
export default {
    components: { 
        PageHeader, 
        HomeBanner, 
        sendForm, 
        GirlList, 
        RecommendList, 
        LoveGift,
        LoveWall, 
        PageBottom, 
        PageFixed,
        userFormList,
        GiftList,
        Pay,
        Upgrade
    },
    data() {
        return{
            pageShow: {
                pageShow: false,
                url: "findPushUser"
            },
            home_img: require("../../assets/images/home_bj.jpg"),
            sayShowc: false,
            sex: "women",
            nickName: "",
            swiperOption: {
                autoplay: true,
                effect : 'coverflow',
                slidesPerView: 6,
                centeredSlides: true,
            },
            recommendShow: false,
            userFormListShow: false
        }
    }
}
</script>
<style scoped>
.page-img{
    height: 100px;
    width: 1200px;
    margin: 0 auto;
    object-fit: cover;
    display: block;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, .1);
    margin-top: 20px
}
.page-banner{
    height: 400px;
    background: salmon;
}
</style>