<template>
    <div class="home-recomwomend">
        <div>
            <div>{{$t('home.recommend')}}</div>
            <div class="more">more+</div>
        </div>
        <div>
            <div 
                @click="goUserXQ(item)"
                @mouseover="itemHover = index" 
                @mouseleave="itemHover = 100" 
                class="home-recomwomend-girl" 
                v-for="(item,index) in girlList.slice(0,7)" 
                :key="index">
                <div class="home-recomwomend-girl-p">官方认证</div>
                <transition name="el-fade-in-linear">
                    <div class="home-recomwomend-girl-s" v-if="itemHover == index">
                        <el-tooltip class="item" effect="dark" content="聊天" placement="top">
                            <i @click.stop="isChat(item)" class="iconfont icon-liaotian"></i>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="收藏" placement="top">
                            <i @click.stop="isCollect(item)" class="iconfont icon-shoucang"></i>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="送礼物" placement="top">
                            <i @click.stop="isGifts(item)" class="iconfont icon-gift-fill"></i>
                        </el-tooltip>
                    </div>
                </transition>
                <img :src="$isApi.http + item.header" alt="">
                <div class="home-recomwomend-girl-n">
                    <i v-if="item.sex == 'women'" class="iconfont icon-xingbienv1"></i>
                    <i v-else style="color:#4A79CC" class="iconfont icon-xingbienan1"></i>
                    <span>{{item.nickName}}</span>
                    <span style="margin-left:4px">{{item.age}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            itemHover: 100,
            girlList: []
        }
    },
    created(){
        this.sendForm = JSON.parse(sessionStorage.getItem('sendForm'))
        this.$nextTick(()=>{
            if(this.sendForm.sex == 'women'){
                this.$isAxios.axiosGet(this.$isApi.showHotUser,{sex:'men'},(res)=>{
                    if(res.data.result){
                        this.girlList = res.data.result
                    }
                })
            }else{
                this.$isAxios.axiosGet(this.$isApi.showHotUser,{sex:'women'},(res)=>{
                    if(res.data.result){
                        this.girlList = res.data.result
                    }
                })
            }
        })
    },
    methods: {
        // 跳转用户详情
        goUserXQ(item){
            this.$router.push({
                path: "/page/userXQ"
            })
        },
        // 跳转聊天
        isChat(){
            setTimeout(()=>{
                this.$store.state.sayShow = true
            },500)
        },
        // 点击收藏
        isCollect(){},
        // 跳转礼品
        isGifts(){}
    }
}
</script>

<style scoped>
.home-recomwomend{
    height: max-content;
    padding: 16px 20px 20px 20px;
    width: 1160px;
    background: #fff;
    margin: 20px auto 0 auto;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, .04);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.home-recomwomend>div:nth-child(1){
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    margin-bottom: 14px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 8px
}
.home-recomwomend>div:nth-child(1)>div:nth-child(1){
    font-size: 14px;
    font-weight: bold;
    color: #333;
}
.home-recomwomend>div:nth-child(2){
    height: max-content;
    display: flex;
}
.home-recomwomend>div:nth-child(2)>div{
    height: max-content;
    width: 122px;
    background: #0f0;
    background-image: linear-gradient(135deg, #ffcc00, #e400ff);
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: hidden;
    padding: 6px;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, .3);
    position: relative;
    cursor: pointer;
    margin-right: 10px;
}
.home-recomwomend>div:nth-child(2)>div:hover>img{
    transform: scale(1.04,1.04);
}
.home-recomwomend>div:nth-child(2)>div>img{
    width: calc( 100% - 4px );
    height: 118px;
    object-fit: cover;
    border-top-left-radius: 9px;
    background: rgba(255, 255, 255, 1);
    border: 2px solid rgba(255, 255, 255, 1);
    transition: transform .2s;
}
.home-recomwomend-girl-n{
    height: 26px;
    font-size: 12px;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.home-recomwomend-girl-n>i{
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #fff;
    color: #FD463E;
    text-align: center;
    line-height: 18px;
    font-weight: bold;
    margin-right: 6px;
    font-size: 10px !important;
}
.home-recomwomend-girl-p{
    position: absolute;
    height: 16px;
    width: max-content;
    padding: 0 8px;
    background-image: linear-gradient(135deg, #ffcc00, #e400ff);
    font-size: 8px !important;
    line-height: 15px;
    left: 12px;
    bottom: 41px;
    color: #FFF;
    border-top-left-radius: 6px;
    border-bottom-right-radius: 6px;
    z-index: 90;
}
.home-recomwomend-girl-s{
    height: 54px;
    position: absolute;
    z-index: 99;
    left: 6px;
    right: 6px;
    top: 42px;
    background: rgba(0, 0, 0, .6);
    text-align: center;
    line-height: 54px;
    color: #fff;
}
.home-recomwomend-girl-s>i{
    font-size: 20px;
    color: #fff;
}
.home-recomwomend-girl-s>i:hover{
    background-image:-webkit-linear-gradient(135deg,#ffcc00, #e400ff);
    -webkit-background-clip:text; 
    -webkit-text-fill-color:transparent;
}
.home-recomwomend-girl-s>i:nth-child(2){
    margin: 0 8px;
    font-size: 21px;
}
</style>
