<template>
    <div class="home-banner-user">
        <div>
            <div class="user-top">
                <div v-if="sendForm.city">{{$t('home.address')}}：{{sendForm.city}}-{{sendForm.area}}</div>
                <div v-else>{{$t('home.address')}}：{{$t('sendUser.noText')}}</div>
                <div>
                    <div>
                        <img v-if="sendForm.header" :src="this.$isApi.http + sendForm.header" alt="">
                    </div>
                    <div>
                        <div>
                            <span v-if="sendForm.nickName" style="color:#f00;font-weight:bold">{{sendForm.nickName}}</span>
                            <span v-else style="margin-right:10px;color:#00f">{{$t('sendUser.noText')}}</span>
                            <span>{{$t('home.welcomeBack')}}~</span>
                        </div>
                        <div>
                            <span>{{$t('home.balance')}}：</span>
                            <span style="color:#f00;font-weight:bold">{{sendForm.balance}}</span>
                            <span style="font-weight:bold;margin-left:10px">$</span>
                        </div>
                        <div>
                            <span>{{$t('home.class')}}：</span>
                            <span v-if="sendForm.level == 0" style="color:#999;font-weight:bold">{{$t('class.vip1')}}</span>
                            <span v-if="sendForm.level == 1" style="color:#ffa800;font-weight:bold">{{$t('class.vip2')}}</span>
                            <span v-if="sendForm.level == 2" style="color:#ff0000;font-weight:bold">{{$t('class.vip3')}}</span>
                        </div>
                        <div v-if="sendForm.level == 1 || sendForm.level == 2" style="font-size:12px">
                            <span>{{$t('class.text')}}：</span>
                            <span style="color:#f00" v-if="sendForm.vipDate">{{$isPulic.timestampToTimeccc(sendForm.vipDate)}}</span>
                        </div>
                        <div @click="goUrl('/page/user/information')" class="user-gouser">{{$t('home.personalCenter')}}</div>
                    </div>
                </div>
            </div>
            <div class="user-bottom">
                <div>{{dateTime}}</div>
                <div @click="goLogin">{{$t('home.loginOut')}}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return{
            dateTime: "",
            sendForm: {}
        }
    },
    created(){
        this.$isAxios.axiosGet(this.$isApi.showUserInfo,{userId:JSON.parse(sessionStorage.getItem('sendForm')).userId},(res)=>{
            this.sendForm = res.data.result
        })
        this.$isPulic.crtime((res,date)=>{
            this.dateTime = date
        })
        setInterval(()=>{
            this.$isPulic.crtime((res,date)=>{
                this.dateTime = date
            })
        },60000)
    },
    methods: {
        // 跳转各页面
        goUrl(url){
            this.$router.push({
                path: url
            })
        },
        // 安全退出
        goLogin(){
            sessionStorage.clear()
            this.$store.state.chatArrForm = []
            this.$store.state.chatForm = {}
            this.$store.state.setContent = {}
            this.$store.state.getContent = {}
            this.$nextTick(()=>{
                window.location.reload()
                this.$router.push({
                    path: "/"
                })
            })
        }
    }
}
</script>

<style scoped>
.home-banner-user{
    position: absolute;
    left: 0;
    right: 0;
    top: 116px;
    height: max-content;
    width: 1200px;
    margin: auto;
    z-index: 95;
    display: flex;
    justify-content: flex-end;
}
.home-banner-user>div{
    width: 380px;
    height: 290px;
    background: rgba(255, 255, 255, .8);
    box-shadow: 4px 4px 10px rgba(0, 0, 0, .3);
    border-radius: 4px;
    overflow: hidden;
}
.user-top{
    height: 200px;
    padding: 20px;
}
.user-top>div:nth-child(1){
    font-size: 14px;
    color: #333;
}
.user-top>div:nth-child(2){
    height: max-content;
    display: flex;
    margin-top: 19px
}
.user-top>div:nth-child(2)>div:nth-child(1){
    width: 130px;
    height: 160px;
    flex-grow: 0;
}
.user-top>div:nth-child(2)>div:nth-child(1)>img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.user-top>div:nth-child(2)>div:nth-child(2){
    flex: 1;
    margin-left: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 1px
}
.user-bottom{
    height: 30px;
    background: #FD463E;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.user-bottom>div:nth-child(1){
    font-size: 16px;
    font-weight: bold;
    color: rgba(255, 255, 255, .8);
}
.user-bottom>div:nth-child(2){
    font-size: 14px;
    color: rgba(255, 255, 255, .6);
    cursor: pointer;
}
.user-bottom>div:nth-child(2):hover{
    color: #fff;
}
.user-gouser{
    padding: 0 20px;
    height: 40px;
    width: max-content;
    background: #FD463E;
    line-height: 39px;
    color: #fff;
    font-size: 14px;
    border-radius: 2px;
    cursor: pointer;
}
.user-gouser:hover{
    background: #f00
}
</style>