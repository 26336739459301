<template>
    <el-carousel height="400px" indicator-position="none" :interval="10000" arrow="always">
        <el-carousel-item v-for="(item,index) in bannerList" :key="index">
            <img style="width:100%;height:100%;object-fit:cover" :src="item.url" alt="">
        </el-carousel-item>
    </el-carousel>
</template>

<script>
export default {
    data() {
        return{
            bannerList: [{
                id: "001",
                url: require("../../assets/images/information_bj.jpg")
            },{
                id: "002",
                url: require("../../assets/images/home_banner.jpg")
            },{
                id: "003",
                url: require("../../assets/images/login_bj.jpg")
            }]
        }
    }
}
</script>